<template>
  <div>
    <v-row v-if="!loading">
      <v-col md="12" v-if="vendorLocationsGridShow">
        <v-card elevation="10">
          <v-toolbar
            dense
            color="#003d6a"
            elevation="0"
            dark
            style="z-index: 9"
          >
            <v-toolbar-title>Firma Lokasyonları</v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="d-flex justify-content-end" style="min-width: 550px">
              <input
                type="text"
                v-model="searchFilterInput"
                class="form-control mr-3"
                style="max-width: 250px"
                placeholder="Arama..."
                v-on:keyup="onFilterTextBoxChanged()"
              />
              <RoleProvider slug="VENDOR_LOCATION_CREATE">
                <div slot-scope="{ canItPass }">
                  <CButton
                    color="light"
                    variant="outline"
                    size="sm"
                    v-if="canItPass"
                    @click="
                      cleanVendorLocationModalAddUpdateForm(),
                        (vendorLocationModalAddUpdateOptions.process = 'post'),
                        (vendorLocationModalAddUpdateOptions.title =
                          'Firma Lokasyonu Ekle'),
                        (vendorLocationModalAddUpdate = true)
                    "
                    class="py-2 px-3 font-weight-bold"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'plus']"
                      class="mr-2"
                    />Yeni Ekle
                  </CButton>
                </div>
              </RoleProvider>
            </div>
          </v-toolbar>

          <CCardBody class="p-0">
            <ag-grid-vue
              style="width: 100%; height: calc(100vh - 145px)"
              class="ag-theme-balham"
              :columnDefs="columnDefs"
              :defaultColDef="defaultColDef"
              :rowData="rowData"
              :sideBar="sideBar"
              :rowSelection="rowSelection"
              :enableRangeSelection="true"
              :statusBar="statusBar"
              @rowDoubleClicked="onRowDoubleClicked"
              @grid-ready="onGridReady"
              :getContextMenuItems="getContextMenuItems"
            ></ag-grid-vue>
          </CCardBody>
        </v-card>
      </v-col>
    </v-row>
    <div class="w-100" v-else>
      <gogo-loading />
    </div>
    <CModal
      :title="vendorLocationModalAddUpdateOptions.title"
      size="xl"
      :closeOnBackdrop="false"
      :show.sync="vendorLocationModalAddUpdate"
      class="vendorLocationModalAddUpdateForm modalNoBorder"
      v-if="vendorLocationModalAddUpdate"
    >
      <CCard class="p-0 m-0 cardShadow">
        <CCardBody>
          <CContainer class="p-0 m-0">
            <CRow>
              <CCol md="12" lg="3">
                <label class>Firma</label>
                <v-autocomplete
                  v-model="vendorLocationModalAddUpdateForm.vendorSlug"
                  :items="vendorLocationModalAddUpdateFormVendorSlugOptions"
                  outlined
                  dense
                  item-text="label"
                  item-value="value"
                ></v-autocomplete>
              </CCol>
              <CCol md="12" lg="3">
                <label class>Durum</label>
                <v-select
                  v-model="vendorLocationModalAddUpdateForm.status"
                  :items="vendorLocationModalAddUpdateFormStatusOptions"
                  outlined
                  dense
                  item-text="label"
                  item-value="value"
                ></v-select>
              </CCol>
              <CCol md="12" lg="4">
                <label class>Lokasyon Adı</label>

                <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="vendorLocationModalAddUpdateForm.vendorLocationName"
                ></v-text-field>
              </CCol>
              <CCol md="12" lg="2">
                <label class>Lokasyon Kodu</label>

                <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="vendorLocationModalAddUpdateForm.vendorLocationCode"
                ></v-text-field>
              </CCol>
              <CCol md="12" lg="3">
                <label class>E-Posta</label>

                <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="vendorLocationModalAddUpdateForm.email"
                ></v-text-field>
              </CCol>
              <CCol md="12" lg="3">
                <label class>Telefon</label>

                <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="vendorLocationModalAddUpdateForm.telephone"
                ></v-text-field>
              </CCol>
              <CCol md="12" lg="3">
                <label class>Şehir</label>

                <v-text-field
                  outlined
                  hide-details
                  dense
                  v-model="vendorLocationModalAddUpdateForm.city"
                ></v-text-field>
              </CCol>

              <CCol md="x12" lg="3">
                <label class>Ülke Kodu</label>

                <v-autocomplete
                  v-model="vendorLocationModalAddUpdateForm.countryCode"
                  :items="countryCodeList"
                  outlined
                  dense
                  item-text="label"
                  item-value="value"
                ></v-autocomplete>
              </CCol>
              <CCol md="12" lg="3">
                <label class>Teslim Yeri</label>
                <v-select
                  v-model="vendorLocationModalAddUpdateForm.deliveryType"
                  :items="vendorLocationModalAddUpdateFormDeliveryTypeOptions"
                  outlined
                  dense
                  :rules="
                    vendorLocationModalAddUpdateForm.status === 'active'
                      ? [rules.required]
                      : null
                  "
                  item-text="label"
                  item-value="value"
                ></v-select>
              </CCol>
              <CCol md="12" lg="3">
                <label class>Eşlenecek Lokasyon</label>
                <v-autocomplete
                  v-model="vendorLocationModalAddUpdateForm.locationSlug"
                  :items="vendorLocationModalAddUpdateFormLocationSlugOptions"
                  outlined
                  dense
                  item-text="label"
                ></v-autocomplete>
              </CCol>
              <CCol md="6">
                <label class>Lokasyon Adres</label>

                <v-textarea
                  outlined
                  dense
                  rows="5"
                  :resize="false"
                  v-model="vendorLocationModalAddUpdateForm.address"
                />
              </CCol>
              <!-- <CCol md="12" lg="3">
                <CInput
                  label="Enlem"
                  v-model="vendorLocationModalAddUpdateForm.locationGeo.lat"
                />
              </CCol>
              <CCol md="12" lg="3">
                <CInput
                  label="Boylam"
                  v-model="vendorLocationModalAddUpdateForm.locationGeo.lng"
                />
              </CCol> -->
            </CRow>
            <CRow class="mt-n6">
              <!-- <CCol class="mt-n6" md="12" lg="4">
                <CRow>
                  <CCol>
                    <CSelect
                      label="Teslim Yeri"
                      :value.sync="
                        vendorLocationModalAddUpdateForm.deliveryType
                      "
                      :options="
                        vendorLocationModalAddUpdateFormDeliveryTypeOptions
                      "
                    />
                  </CCol>
                </CRow>
                <CRow class="mt-n6">
                  <CCol>
                    <label class>Eşlenecek Lokasyon</label>
                    <v-autocomplete
                      class="mt-1"
                      v-model="vendorLocationModalAddUpdateForm.locationSlug"
                      :items="
                        vendorLocationModalAddUpdateFormLocationSlugOptions
                      "
                      outlined
                      dense
                      item-text="label"
                    ></v-autocomplete>
                  </CCol>
                </CRow>
              </CCol> -->
              <!-- <CCol md="12">
                <CTextarea
                  label="Lokasyon Adres"
                  rows="5"
                  :resize="false"
                  v-model="vendorLocationModalAddUpdateForm.address"
                />
              </CCol> -->
            </CRow>
            <CRow class="mt-n8">
              <CCol md="12" lg="6">
                <CInputCheckbox
                  id="flexCheckDefault"
                  label="Biniş Kartı Zorunluluğu Var"
                  @click="
                    vendorLocationModalAddUpdateForm.boardingpass =
                      !vendorLocationModalAddUpdateForm.boardingpass
                  "
                  :checked="vendorLocationModalAddUpdateForm.boardingpass"
                />
              </CCol>
            </CRow>

            <div
              v-if="vendorLocationModalAddUpdateForm.locationGeo"
              class="mt-4"
            >
              <MapLocation
                :initial-location="vendorLocationModalAddUpdateForm.locationGeo"
                @location-updated="
                  (a) => {
                    vendorLocationModalAddUpdateForm.locationGeo = a;
                  }
                "
              />
              <!-- <iframe
                title="Google Maps"
                width="100%"
                height="300"
                class="location-google-map"
                :src="`https://maps.google.com/maps?width=700&amp;height=440&amp;hl=en&amp;q=${vendorLocationModalAddUpdateForm.locationGeo[1]},${vendorLocationModalAddUpdateForm.locationGeo[0]}&amp;ie=UTF8&amp;t=&amp;z=10&amp;iwloc=B&amp;output=embed`"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe> -->
            </div>
          </CContainer>
        </CCardBody>
      </CCard>
      <template #footer>
        <RoleProvider slug="VENDOR_LOCATION_CREATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="success"
              v-if="
                vendorLocationModalAddUpdateOptions.process == 'post' &&
                canItPass
              "
              :disabled="vendorLocationModalAddUpdateBtnDisable"
              @click="vendorLocationAddUpdate()"
              >Ekle</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider slug="VENDOR_LOCATION_DELETE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="danger"
              v-if="
                vendorLocationModalAddUpdateOptions.process == 'put' &&
                canItPass
              "
              @click="deleted(vendorLocationModalAddUpdateForm._id)"
              >Sil</CButton
            >
          </div>
        </RoleProvider>
        <RoleProvider slug="VENDOR_LOCATION_UPDATE">
          <div slot-scope="{ canItPass }">
            <CButton
              color="success"
              v-if="
                vendorLocationModalAddUpdateOptions.process == 'put' &&
                canItPass
              "
              :disabled="vendorLocationModalAddUpdateBtnDisable"
              @click="vendorLocationAddUpdate()"
              >Düzenle</CButton
            >
          </div>
        </RoleProvider>
      </template>
    </CModal>

    <CModal
      title="Toplu Güncelleme"
      size="xl"
      :closeOnBackdrop="false"
      :show.sync="pairingModalStatus"
      class="pairingModal modalNoBorder"
    >
      <CCard class="p-0 m-0 cardShadow">
        <CCardBody>
          <CContainer class="p-0 m-0">
            <CRow>
              <CCol md="12" lg="12">
                <CAlert show color="info">
                  Seçtiğiniz
                  <strong>{{ pairingForm.locationIds.length }} lokasyon</strong>
                  toplu olarak güncellenecek!
                </CAlert>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12" lg="2">
                <CSelect
                  label="Durum"
                  :value.sync="pairingForm.status"
                  :options="vendorLocationModalAddUpdateFormStatusOptions"
                />
              </CCol>
            </CRow>
          </CContainer>
        </CCardBody>
      </CCard>
      <template #footer>
        <CButton
          color="success"
          :disabled="vendorLocationModalAddUpdateBtnDisable"
          @click="vendorLocationPairing()"
          >Güncelle</CButton
        >
      </template>
    </CModal>
    <CModal
      title="Toplu Lokasyon Güncelleme"
      size="xl"
      :closeOnBackdrop="false"
      :show.sync="pairingLocationModalStatus"
      class="pairingModal modalNoBorder"
      v-if="pairingLocationModalStatus"
    >
      <CCard class="p-0 m-0 cardShadow">
        <CCardBody>
          <CContainer class="p-0 m-0">
            <CRow>
              <CCol md="12" lg="12">
                <CAlert show color="info">
                  Seçtiğiniz
                  <strong>{{ pairingForm.locationIds.length }} lokasyon</strong>
                  toplu olarak güncellenecek!
                </CAlert>
              </CCol>
            </CRow>
            <CRow>
              <CCol md="12">
                <MapLocation
                  :initial-location="{
                    lat: 0,
                    lng: 0,
                  }"
                  @location-updated="
                    (a) => {
                      pairingForm.locationGeo = a;
                    }
                  "
                />
              </CCol>
            </CRow>
          </CContainer>
        </CCardBody>
      </CCard>
      <template #footer>
        <CButton
          color="success"
          :disabled="vendorLocationModalAddUpdateBtnDisable"
          @click="updateLocationGeo()"
          >Güncelle</CButton
        >
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import Func from "../../func";
import { AgGridVue } from "ag-grid-vue";
import VueSweetalert2 from "vue-sweetalert2";

import { RoleProvider } from "../../provider";
import GogoLoading from "../../components/GogoLoading.vue";
import MapLocation from "../../components/MapLocation.vue";
import "vue-select/dist/vue-select.css";
var _ = require("lodash");
axios.defaults.withCredentials = true;
Vue.use(VueSweetalert2);

export default {
  name: "VendorLocationSearch",
  created() {
    // if (this.$route.params.vendorSlug != undefined) {
    // 	this.locationForm.carBrand = this.$route.params.vendorSlug;
    // 	this.locationSearch();
    // }

    this.getVendorList();
    this.getLocationList();
    this.searchVendorLocation();
    this.lastFilter();
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Zorunlu alan.",
      },
      pairingModalStatus: false,
      pairingLocationModalStatus: false,
      searchFilterInput: "",
      vendorLocationsGridShow: false,
      columnDefs: [
        {
          field: "status",
          cellRenderer: (params) => {
            const v = params.value;
            return v === "active"
              ? "Aktif"
              : v === "pending"
              ? "Bekliyor"
              : v === "passive"
              ? "Pasif"
              : "Bilinmiyor";
          },
          cellStyle: (params) => {
            const v = params.value;

            return v === "active"
              ? {
                  color: "#2eb85c",
                  fontWeight: "bold",
                }
              : v === "pending"
              ? {
                  color: "#F7DC6F",
                  fontWeight: "bold",
                }
              : {
                  color: "#e55353",
                  fontWeight: "bold",
                };
          },
          headerName: "Durum",
          checkboxSelection: true,
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          width: 110,
        },
        {
          field: "vendorDetail.brand",
          headerName: "Firma",
          width: 150,
        },

        {
          field: "vendorLocationName",
          tooltipField: "vendorLocationName",
          headerName: "Lokasyon Adı",
          width: 300,
        },
        {
          field: "locationDetail.locationName",
          cellRenderer: (params) => {
            if (params.value) return params.value;
            else return "Lokasyon eşleştirilmemiş!";
          },
          cellStyle: (params) => {
            if (!params.value) return { color: "#e55353", fontWeight: "bold" };
          },
          tooltipField: "locationDetail.locationName",
          headerName: "Eşleştirilen Lokasyon Adı",
          width: 300,
        },
        {
          field: "locationGeo.coordinates",
          headerName: "Konum",
          width: 150,
        },
        {
          field: "countryCode",
          headerName: "Ülke",
          cellRenderer: (params) => {
            const result = this.countryCodeList.filter(
              (item) => item.value === params.value
            );
            console.log("sasdf", result);
            return result[0].label;
          },
          width: 150,
        },
        {
          field: "vendorLocationCode",
          headerName: "Lokasyon Kodu",
          width: 150,
        },
        {
          field: "city",
          cellRenderer: (params) => {
            return Func.strOnlyFirstCharUpperCase(params.value);
          },
          headerName: "Şehir",
          width: 120,
        },
        {
          field: "address",
          cellRenderer: (params) => {
            return Func.strInitialsUpperCase(params.value);
          },
          tooltipField: "address",
          headerName: "Adres",
          width: 250,
        },
        {
          field: "deliveryType",
          cellRenderer: (params) => {
            if (params.value === "centralOffice") return "Ofisten Teslim";
            else if (params.value === "inTerminal") return "Havalimanı Ofisi";
            else if (params.value === "welcoming") return "Buluşma Noktası";
            else if (params.value === "freeShuttle") return "Ücretsiz Servis";
            else if (params.value === "vale") return "Vale";
            else return "-";
          },
          headerName: "Teslim Tipi",
          width: 150,
        },

        {
          field: "email",
          headerName: "Email",
          width: 190,
        },
        {
          field: "telephone",

          headerName: "Telefon",
          width: 130,
        },
      ],
      defaultColDef: {
        sortable: true,
        resizable: true,
        filter: true,
        filterParams: {
          newRowsAction: "keep",
        },
      },
      rowData: [],
      sideBar: {
        // Grid yan çubuk tanımlaması
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Sütunlar",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
          },
          ,
          {
            id: "filters",
            labelDefault: "Filtreler",
            labelKey: "filters",
            iconKey: "filter",
            toolPanel: "agFiltersToolPanel",
          },
        ],
        defaultToolPanel: "",
      },
      rowSelection: "multiple", // Satır Seçim Türü
      statusBar: {
        // Durum çubuğunda kullanılacak durum çubuğu bileşenlerini belirtir.
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left",
          },
          {
            statusPanel: "agTotalRowCountComponent",
            align: "center",
          },
          { statusPanel: "agFilteredRowCountComponent" },
          { statusPanel: "agSelectedRowCountComponent" },
          { statusPanel: "agAggregationComponent" },
        ],
      },
      // FİRMA LOKASYONU ARAMA
      searchVendorLocationFormCollapsed: false,
      searchVendorLocationFormStatusOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: "active",
          label: "Aktif",
        },
        {
          value: "pending",
          label: "Bekliyor",
        },
        {
          value: "passive",
          label: "Pasif",
        },
      ],
      searchVendorLocationFormVendorSlugOptions: [
        {
          value: "",
          label: "Tümü",
        },
      ],
      searchVendorLocationFormLocationSlugOptions: [
        {
          value: "",
          label: "Tümü",
        },
      ],
      searchVendorLocationFormDeliveryTypeOptions: [
        {
          value: "",
          label: "Tümü",
        },
        {
          value: "centralOffice",
          label: "Ofisten Teslim",
        },
        {
          value: "inTerminal",
          label: "Havalimanı Ofisi",
        },
        {
          value: "welcoming",
          label: "Buluşma Noktası",
        },
      ],
      searchVendorLocationForm: {
        status: "",
        vendorSlug: "",
        locationSlug: "",
        vendorLocationCode: "",
        countryCode: "",
        city: "",
        deliveryType: "",
      },
      // FİRMA LOKASYONU EKLEME GÜNCELLEME MODAL
      vendorLocationModalAddUpdate: false, // VendorLocation Modal Add Update
      vendorLocationModalAddUpdateBtnDisable: false, // VendorLocation Modal Add Update button disabled enabled değişkeni
      vendorLocationModalAddUpdateOptions: {
        // VendorLocation Modal Add Update değişkenleri
        process: "",
        title: "",
        shake: {
          vendorLocationName: false,
          vendorLocationCode: false,
          city: false,
          countryCode: false,
          address: false,
          email: false,
          telephone: false,
        },
      },
      vendorLocationModalAddUpdateFormStatusOptions: [
        {
          value: "active",
          label: "Aktif",
        },
        {
          value: "pending",
          label: "Bekliyor",
        },
        {
          value: "passive",
          label: "Pasif",
        },
      ],
      vendorLocationModalAddUpdateFormVendorSlugOptions: [],
      vendorLocationModalAddUpdateFormDeliveryTypeOptions: [
        {
          value: "centralOffice",
          label: "Ofisten Teslim",
        },
        {
          value: "inTerminal",
          label: "Havalimanı Ofisi",
        },
        {
          value: "welcoming",
          label: "Buluşma Noktası",
        },
      ],
      vendorLocationModalAddUpdateFormLocationSlugOptions: [],
      vendorLocationModalAddUpdateFormErrors: {
        vendorLocationName: "",
        vendorLocationCode: "",
        city: "",
        countryCode: "",
        address: "",
        email: "",
        telephone: "",
      },
      vendorLocationModalAddUpdateForm: {
        _id: "",
        status: false,
        vendorLocationName: "",
        vendorLocationCode: "",
        city: "",
        countryCode: "",
        address: "",
        vendorSlug: "",
        email: "",
        telephone: "",
        deliveryType: "",
        locationSlug: "",
        boardingpass: false,
        locationGeo: {
          lat: 0,
          lng: 0,
        },
      },
      pairingForm: {
        status: true,
        deliveryType: "",
        locationSlug: "",
        locationIds: [],
        locationGeo: {},
      },
      loading: false,
      search: "",
      autocomplete: null,
      locationItems: [],
      countryCodeList: [
        { label: "Afghanistan", value: "AF" },
        { label: "Aland Islands", value: "AX" },
        { label: "Albania", value: "AL" },
        { label: "Algeria", value: "DZ" },
        { label: "American Samoa", value: "AS" },
        { label: "Andorra", value: "AD" },
        { label: "Angola", value: "AO" },
        { label: "Anguilla", value: "AI" },
        { label: "Antarctica", value: "AQ" },
        { label: "Antigua And Barbuda", value: "AG" },
        { label: "Argentina", value: "AR" },
        { label: "Armenia", value: "AM" },
        { label: "Aruba", value: "AW" },
        { label: "Australia", value: "AU" },
        { label: "Austria", value: "AT" },
        { label: "Azerbaijan", value: "AZ" },
        { label: "Bahamas", value: "BS" },
        { label: "Bahrain", value: "BH" },
        { label: "Bangladesh", value: "BD" },
        { label: "Barbados", value: "BB" },
        { label: "Belarus", value: "BY" },
        { label: "Belgium", value: "BE" },
        { label: "Belize", value: "BZ" },
        { label: "Benin", value: "BJ" },
        { label: "Bermuda", value: "BM" },
        { label: "Bhutan", value: "BT" },
        { label: "Bolivia", value: "BO" },
        { label: "Bosnia And Herzegovina", value: "BA" },
        { label: "Botswana", value: "BW" },
        { label: "Bouvet Island", value: "BV" },
        { label: "Brazil", value: "BR" },
        { label: "British Indian Ocean Territory", value: "IO" },
        { label: "Brunei Darussalam", value: "BN" },
        { label: "Bulgaria", value: "BG" },
        { label: "Burkina Faso", value: "BF" },
        { label: "Burundi", value: "BI" },
        { label: "Cambodia", value: "KH" },
        { label: "Cameroon", value: "CM" },
        { label: "Canada", value: "CA" },
        { label: "Cape Verde", value: "CV" },
        { label: "Cayman Islands", value: "KY" },
        { label: "Central African Republic", value: "CF" },
        { label: "Chad", value: "TD" },
        { label: "Chile", value: "CL" },
        { label: "China", value: "CN" },
        { label: "Christmas Island", value: "CX" },
        { label: "Cocos (Keeling) Islands", value: "CC" },
        { label: "Colombia", value: "CO" },
        { label: "Comoros", value: "KM" },
        { label: "Congo", value: "CG" },
        { label: "Congo, Democratic Republic", value: "CD" },
        { label: "Cook Islands", value: "CK" },
        { label: "Costa Rica", value: "CR" },
        { label: "Cote D'Ivoire", value: "CI" },
        { label: "Croatia", value: "HR" },
        { label: "Cuba", value: "CU" },
        { label: "Cyprus", value: "CY" },
        { label: "Czech Republic", value: "CZ" },
        { label: "Denmark", value: "DK" },
        { label: "Djibouti", value: "DJ" },
        { label: "Dominica", value: "DM" },
        { label: "Dominican Republic", value: "DO" },
        { label: "Ecuador", value: "EC" },
        { label: "Egypt", value: "EG" },
        { label: "El Salvador", value: "SV" },
        { label: "Equatorial Guinea", value: "GQ" },
        { label: "Eritrea", value: "ER" },
        { label: "Estonia", value: "EE" },
        { label: "Ethiopia", value: "ET" },
        { label: "Falkland Islands (Malvinas)", value: "FK" },
        { label: "Faroe Islands", value: "FO" },
        { label: "Fiji", value: "FJ" },
        { label: "Finland", value: "FI" },
        { label: "France", value: "FR" },
        { label: "French Guiana", value: "GF" },
        { label: "French Polynesia", value: "PF" },
        { label: "French Southern Territories", value: "TF" },
        { label: "Gabon", value: "GA" },
        { label: "Gambia", value: "GM" },
        { label: "Georgia", value: "GE" },
        { label: "Germany", value: "DE" },
        { label: "Ghana", value: "GH" },
        { label: "Gibraltar", value: "GI" },
        { label: "Greece", value: "GR" },
        { label: "Greenland", value: "GL" },
        { label: "Grenada", value: "GD" },
        { label: "Guadeloupe", value: "GP" },
        { label: "Guam", value: "GU" },
        { label: "Guatemala", value: "GT" },
        { label: "Guernsey", value: "GG" },
        { label: "Guinea", value: "GN" },
        { label: "Guinea-Bissau", value: "GW" },
        { label: "Guyana", value: "GY" },
        { label: "Haiti", value: "HT" },
        { label: "Heard Island & Mcdonald Islands", value: "HM" },
        { label: "Holy See (Vatican City State)", value: "VA" },
        { label: "Honduras", value: "HN" },
        { label: "Hong Kong", value: "HK" },
        { label: "Hungary", value: "HU" },
        { label: "Iceland", value: "IS" },
        { label: "India", value: "IN" },
        { label: "Indonesia", value: "ID" },
        { label: "Iran, Islamic Republic Of", value: "IR" },
        { label: "Iraq", value: "IQ" },
        { label: "Ireland", value: "IE" },
        { label: "Isle Of Man", value: "IM" },
        { label: "Israel", value: "IL" },
        { label: "Italy", value: "IT" },
        { label: "Jamaica", value: "JM" },
        { label: "Japan", value: "JP" },
        { label: "Jersey", value: "JE" },
        { label: "Jordan", value: "JO" },
        { label: "Kazakhstan", value: "KZ" },
        { label: "Kenya", value: "KE" },
        { label: "Kiribati", value: "KI" },
        { label: "Korea", value: "KR" },
        { label: "North Korea", value: "KP" },
        { label: "Kuwait", value: "KW" },
        { label: "Kyrgyzstan", value: "KG" },
        { label: "Lao People's Democratic Republic", value: "LA" },
        { label: "Latvia", value: "LV" },
        { label: "Lebanon", value: "LB" },
        { label: "Lesotho", value: "LS" },
        { label: "Liberia", value: "LR" },
        { label: "Libyan Arab Jamahiriya", value: "LY" },
        { label: "Liechtenstein", value: "LI" },
        { label: "Lithuania", value: "LT" },
        { label: "Luxembourg", value: "LU" },
        { label: "Macao", value: "MO" },
        { label: "Macedonia", value: "MK" },
        { label: "Madagascar", value: "MG" },
        { label: "Malawi", value: "MW" },
        { label: "Malaysia", value: "MY" },
        { label: "Maldives", value: "MV" },
        { label: "Mali", value: "ML" },
        { label: "Malta", value: "MT" },
        { label: "Marshall Islands", value: "MH" },
        { label: "Martinique", value: "MQ" },
        { label: "Mauritania", value: "MR" },
        { label: "Mauritius", value: "MU" },
        { label: "Mayotte", value: "YT" },
        { label: "Mexico", value: "MX" },
        { label: "Micronesia", value: "FM" },
        { label: "Moldova", value: "MD" },
        { label: "Monaco", value: "MC" },
        { label: "Mongolia", value: "MN" },
        { label: "Montenegro", value: "ME" },
        { label: "Montserrat", value: "MS" },
        { label: "Morocco", value: "MA" },
        { label: "Mozambique", value: "MZ" },
        { label: "Myanmar", value: "MM" },
        { label: "Namibia", value: "NA" },
        { label: "Nauru", value: "NR" },
        { label: "Nepal", value: "NP" },
        { label: "Netherlands", value: "NL" },
        { label: "Netherlands Antilles", value: "AN" },
        { label: "New Caledonia", value: "NC" },
        { label: "New Zealand", value: "NZ" },
        { label: "Nicaragua", value: "NI" },
        { label: "Niger", value: "NE" },
        { label: "Nigeria", value: "NG" },
        { label: "Niue", value: "NU" },
        { label: "Norfolk Island", value: "NF" },
        { label: "Northern Mariana Islands", value: "MP" },
        { label: "Norway", value: "NO" },
        { label: "Oman", value: "OM" },
        { label: "Pakistan", value: "PK" },
        { label: "Palau", value: "PW" },
        { label: "Palestinian Territory, Occupied", value: "PS" },
        { label: "Panama", value: "PA" },
        { label: "Papua New Guinea", value: "PG" },
        { label: "Paraguay", value: "PY" },
        { label: "Peru", value: "PE" },
        { label: "Philippines", value: "PH" },
        { label: "Pitcairn", value: "PN" },
        { label: "Poland", value: "PL" },
        { label: "Portugal", value: "PT" },
        { label: "Puerto Rico", value: "PR" },
        { label: "Qatar", value: "QA" },
        { label: "Reunion", value: "RE" },
        { label: "Romania", value: "RO" },
        { label: "Russian Federation", value: "RU" },
        { label: "Rwanda", value: "RW" },
        { label: "Saint Barthelemy", value: "BL" },
        { label: "Saint Helena", value: "SH" },
        { label: "Saint Kitts And Nevis", value: "KN" },
        { label: "Saint Lucia", value: "LC" },
        { label: "Saint Martin", value: "MF" },
        { label: "Saint Pierre And Miquelon", value: "PM" },
        { label: "Saint Vincent And Grenadines", value: "VC" },
        { label: "Samoa", value: "WS" },
        { label: "San Marino", value: "SM" },
        { label: "Sao Tome And Principe", value: "ST" },
        { label: "Saudi Arabia", value: "SA" },
        { label: "Senegal", value: "SN" },
        { label: "Serbia", value: "RS" },
        { label: "Seychelles", value: "SC" },
        { label: "Sierra Leone", value: "SL" },
        { label: "Singapore", value: "SG" },
        { label: "Slovakia", value: "SK" },
        { label: "Slovenia", value: "SI" },
        { label: "Solomon Islands", value: "SB" },
        { label: "Somalia", value: "SO" },
        { label: "South Africa", value: "ZA" },
        { label: "South Georgia And Sandwich Isl.", value: "GS" },
        { label: "Spain", value: "ES" },
        { label: "Sri Lanka", value: "LK" },
        { label: "Sudan", value: "SD" },
        { label: "Suriname", value: "SR" },
        { label: "Svalbard And Jan Mayen", value: "SJ" },
        { label: "Swaziland", value: "SZ" },
        { label: "Sweden", value: "SE" },
        { label: "Switzerland", value: "CH" },
        { label: "Syrian Arab Republic", value: "SY" },
        { label: "Taiwan", value: "TW" },
        { label: "Tajikistan", value: "TJ" },
        { label: "Tanzania", value: "TZ" },
        { label: "Thailand", value: "TH" },
        { label: "Timor-Leste", value: "TL" },
        { label: "Togo", value: "TG" },
        { label: "Tokelau", value: "TK" },
        { label: "Tonga", value: "TO" },
        { label: "Trinidad And Tobago", value: "TT" },
        { label: "Tunisia", value: "TN" },
        { label: "Turkey", value: "TR" },
        { label: "Turkmenistan", value: "TM" },
        { label: "Turks And Caicos Islands", value: "TC" },
        { label: "Tuvalu", value: "TV" },
        { label: "Uganda", value: "UG" },
        { label: "Ukraine", value: "UA" },
        { label: "United Arab Emirates", value: "AE" },
        { label: "United Kingdom", value: "GB" },
        { label: "United States", value: "US" },
        { label: "United States Outlying Islands", value: "UM" },
        { label: "Uruguay", value: "UY" },
        { label: "Uzbekistan", value: "UZ" },
        { label: "Vanuatu", value: "VU" },
        { label: "Venezuela", value: "VE" },
        { label: "Viet Nam", value: "VN" },
        { label: "Virgin Islands, British", value: "VG" },
        { label: "Virgin Islands, U.S.", value: "VI" },
        { label: "Wallis And Futuna", value: "WF" },
        { label: "Western Sahara", value: "EH" },
        { label: "Yemen", value: "YE" },
        { label: "Zambia", value: "ZM" },
        { label: "Zimbabwe", value: "ZW" },
      ],
    };
  },
  components: {
    AgGridVue,
    RoleProvider,
    GogoLoading,
    MapLocation,
  },

  watch: {
    "vendorLocationModalAddUpdateForm.brand": function () {
      if (this.vendorLocationModalAddUpdateForm.brand != "")
        this.vendorLocationModalAddUpdateForm.brandSlug = Func.slugify(
          this.vendorLocationModalAddUpdateForm.brand
        );
      else this.vendorLocationModalAddUpdateForm.brandSlug = "";
    },
  },
  methods: {
    lastFilter() {
      this.searchFilterInput =
        JSON.parse(localStorage.getItem("VendorslastFilter") || "{}")
          .VendorLocationPageFilter || "";
    },
    getContextMenuItems(params) {
      var _this = this;
      var selectedRowCount = params.api.getSelectedRows().length;
      var result = [
        {
          name: "Güncelle (" + selectedRowCount + " Lokasyon)",
          disabled: selectedRowCount === 0 ? true : false,
          action: function () {
            var selectedRows = params.api.getSelectedRows();
            _this.pairingForm.locationIds = [];
            for (const rowId of selectedRows) {
              _this.pairingForm.locationIds.push(rowId._id);
            }
            _this.pairingModalStatus = true;
          },
          cssClasses: ["redFont", "bold"],
        },
        {
          name: "Lokasyon Güncelle (" + selectedRowCount + " Lokasyon)",
          disabled: selectedRowCount === 0 ? true : false,
          action: function () {
            var selectedRows = params.api.getSelectedRows();
            _this.pairingForm.locationIds = [];
            for (const rowId of selectedRows) {
              _this.pairingForm.locationIds.push(rowId._id);
            }
            _this.pairingLocationModalStatus = true;
          },
          cssClasses: ["redFont", "bold"],
        },
        "separator",
        "copy",
        "export",
      ];
      return result;
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.searchFilterInput);
      localStorage.setItem(
        "VendorslastFilter",
        JSON.stringify({ VendorLocationPageFilter: this.searchFilterInput })
      );
    },
    onGridReady(params) {
      // Grid ready olduğunda
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    getGridContextMenuItems(params) {
      var _this = this;
      var result = [
        {
          name: "Sil",
          action: function () {
            Vue.swal({
              icon: "error",
              title: "Lokasyon Sil",
              html:
                "<strong>" +
                params.node.data.vendorDetail.brand +
                "</strong> isimli firmaya ait <strong>" +
                params.node.data.vendorLocationName +
                "</strong> lokasyonunu silmek istiyor musunuz?",
              showDenyButton: true,
              confirmButtonText: "Evet",
              denyButtonText: "Hayır",
            }).then((result) => {
              if (result.isConfirmed)
                _this.vendorLocationDelete({ _id: params.node.data._id });
            });
          },
        },
        {
          name: "Düzenle",
          action: function () {
            _this.cleanVendorLocationModalAddUpdateForm();
            _this.vendorLocationModalAddUpdateOptions.process = "put";
            _this.vendorLocationModalAddUpdateOptions.title =
              "Lokasyon Güncelle";

            _this.vendorLocationModalAddUpdateForm._id = params.node.data._id;
            _this.vendorLocationModalAddUpdateForm.status =
              params.node.data.status;
            _this.vendorLocationModalAddUpdateForm.vendorLocationName =
              params.node.data.vendorLocationName;
            _this.vendorLocationModalAddUpdateForm.vendorLocationCode =
              params.node.data.vendorLocationCode;
            _this.vendorLocationModalAddUpdateForm.city = params.node.data.city;
            _this.vendorLocationModalAddUpdateForm.countryCode =
              params.node.data.countryCode;
            _this.vendorLocationModalAddUpdateForm.address =
              params.node.data.address;
            _this.vendorLocationModalAddUpdateForm.vendorSlug =
              params.node.data.vendorSlug;
            _this.vendorLocationModalAddUpdateForm.email =
              params.node.data.email;
            _this.vendorLocationModalAddUpdateForm.telephone =
              params.node.data.telephone;
            _this.vendorLocationModalAddUpdateForm.deliveryType =
              params.node.data.deliveryType;
            _this.vendorLocationModalAddUpdateForm.locationSlug =
              params.node.data.locationSlug;

            _this.vendorLocationModalAddUpdate = true;
          },
        },
        {
          name: "Kopyala",
          action: function () {
            _this.gridApi.copySelectedRangeToClipboard();
          },
        },
        "separator",
        "chartRange",
      ];
      return result;
    },
    validVendorLocationName(val) {
      if (val) {
        if (val.toString().length > 50) {
          this.vendorLocationModalAddUpdateFormErrors.vendorLocationName =
            "Bu alana en fazla 50 karakter girilebilir!";
          return false;
        } else {
          this.vendorLocationModalAddUpdateFormErrors.vendorLocationName = "";
          return true;
        }
      } else {
        this.vendorLocationModalAddUpdateFormErrors.vendorLocationName = "";
        //"Bu alan boş bırakılamaz!";
        return false;
      }
    },
    validVendorLocationCode(val) {
      if (val) {
        if (val.toString().length > 30) {
          this.vendorLocationModalAddUpdateFormErrors.vendorLocationCode =
            "Bu alana en fazla 30 karakter girilebilir!";
          return false;
        } else {
          this.vendorLocationModalAddUpdateFormErrors.vendorLocationCode = "";
          return true;
        }
      } else {
        this.vendorLocationModalAddUpdateFormErrors.vendorLocationCode = "";
        //"Bu alan boş bırakılamaz!";
        return false;
      }
    },
    validCity(val) {
      if (val) {
        if (val.toString().length > 30) {
          this.vendorLocationModalAddUpdateFormErrors.city =
            "Bu alana en fazla 30 karakter girilebilir!";
          return false;
        } else {
          this.vendorLocationModalAddUpdateFormErrors.city = "";
          return true;
        }
      } else {
        this.vendorLocationModalAddUpdateFormErrors.city = "";
        //"Bu alan boş bırakılamaz!";
        return false;
      }
    },
    validCountryCode(val) {
      if (val) {
        if (val.toString().length > 10) {
          this.vendorLocationModalAddUpdateFormErrors.countryCode =
            "Bu alana en fazla 10 karakter girilebilir!";
          return false;
        } else {
          this.vendorLocationModalAddUpdateFormErrors.countryCode = "";
          return true;
        }
      } else {
        this.vendorLocationModalAddUpdateFormErrors.countryCode = "";
        //"Bu alan boş bırakılamaz!";
        return false;
      }
    },
    validAddress(val) {
      if (val) {
        if (val.toString().length > 200) {
          this.vendorLocationModalAddUpdateFormErrors.address =
            "Bu alana en fazla 200 karakter girilebilir!";
          return false;
        } else {
          this.vendorLocationModalAddUpdateFormErrors.address = "";
          return true;
        }
      } else {
        this.vendorLocationModalAddUpdateFormErrors.address = "";
        //"Bu alan boş bırakılamaz!";
        return false;
      }
    },
    validEmail(val) {
      if (val) {
        if (val.toString().length > 50) {
          this.vendorLocationModalAddUpdateFormErrors.email =
            "Bu alana en fazla 50 karakter girilebilir!";
          return false;
        } else {
          this.vendorLocationModalAddUpdateFormErrors.email = "";
          return true;
        }
      } else {
        this.vendorLocationModalAddUpdateFormErrors.email = "";
        //"Bu alan boş bırakılamaz!";
        return false;
      }
    },
    validTelephone(val) {
      if (val) {
        if (val.toString().length > 25) {
          this.vendorLocationModalAddUpdateFormErrors.telephone =
            "Bu alana en fazla 25 karakter girilebilir!";
          return false;
        } else {
          this.vendorLocationModalAddUpdateFormErrors.telephone = "";
          return true;
        }
      } else {
        this.vendorLocationModalAddUpdateFormErrors.telephone = "";
        //"Bu alan boş bırakılamaz!";
        return false;
      }
    },
    cleanVendorLocationModalAddUpdateForm() {
      this.vendorLocationModalAddUpdateForm._id = "";
      this.vendorLocationModalAddUpdateForm.status =
        this.vendorLocationModalAddUpdateFormStatusOptions[0]?.value;
      this.vendorLocationModalAddUpdateForm.locationGeo.lat = 0;
      this.vendorLocationModalAddUpdateForm.locationGeo.lng = 0;
      this.vendorLocationModalAddUpdateForm.vendorLocationName = "";
      this.vendorLocationModalAddUpdateForm.vendorLocationCode = "";
      this.vendorLocationModalAddUpdateForm.city = "";
      this.vendorLocationModalAddUpdateForm.countryCode = "";
      this.vendorLocationModalAddUpdateForm.address = "";
      this.vendorLocationModalAddUpdateForm.vendorSlug =
        this.vendorLocationModalAddUpdateFormVendorSlugOptions[0]?.value;
      this.vendorLocationModalAddUpdateForm.email = "";
      this.vendorLocationModalAddUpdateForm.telephone = "";
      this.vendorLocationModalAddUpdateForm.deliveryType =
        this.vendorLocationModalAddUpdateFormDeliveryTypeOptions[0]?.value;
      this.vendorLocationModalAddUpdateForm.locationSlug =
        this.vendorLocationModalAddUpdateFormLocationSlugOptions[0]?.value;
    },
    getVendorList() {
      var _this = this;

      axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor")
        .then((response) => {
          if (response.data.vendorList.length > 0) {
            response.data.vendorList.map(function (result) {
              _this.searchVendorLocationFormVendorSlugOptions.push({
                label: result.brand,
                value: result.brandSlug,
              });

              _this.vendorLocationModalAddUpdateFormVendorSlugOptions.push({
                label: result.brand,
                value: result.brandSlug,
              });
            });
          }
        });
    },
    getLocationList() {
      var _this = this;

      axios
        .get(process.env.VUE_APP_API_URL + "admin/location?status=true")
        .then((response) => {
          if (response.data.locationList.length > 0) {
            response.data.locationList.map(function (result) {
              _this.searchVendorLocationFormLocationSlugOptions.push({
                label: result.locationName,
                value: result.slug,
              });

              _this.vendorLocationModalAddUpdateFormLocationSlugOptions.push({
                label: result.locationName,
                value: result.slug,
              });
            });
          }
        });
    },
    searchVendorLocation() {
      this.$Progress.start();
      let params = this.searchVendorLocationForm,
        existQuery = this.$route?.query?.existQuery,
        status = this.$route?.query?.status;
      if (existQuery) params.existQuery = existQuery;
      if (status) params.status = status;
      axios
        .get(process.env.VUE_APP_API_URL + "admin/vendor/locations", {
          params,
        })
        .then((response) => {
          this.rowData = _.reverse(response.data.vendorLocationList);
          this.searchVendorLocationFormCollapsed = false;
          this.vendorLocationsGridShow = true;
          this.$Progress.finish();
        });
    },
    vendorLocationAddUpdate() {
      Func.submitValidController(
        this.vendorLocationModalAddUpdateFormErrors,
        this.vendorLocationModalAddUpdateOptions.shake,
        (result) => {
          if (result) {
            // true ise form validation hatası yok
            this.$Progress.start(); // Progress true ise gösteriyoruz
            this.vendorLocationModalAddUpdateBtnDisable = true; // İşlemi yaptıran button'u disable ettik.
            axios[this.vendorLocationModalAddUpdateOptions.process](
              process.env.VUE_APP_API_URL + "admin/vendor/locations",
              this.vendorLocationModalAddUpdateForm,
              { withCredentials: true }
            ).then((response) => {
              this.$Progress.finish(); // Progress true ise gizliyoruz
              this.vendorLocationModalAddUpdateBtnDisable = false; // İşlemi yaptıran button'u enable ettik.

              if (response.data.result === "success") {
                if (this.vendorLocationsGridShow) this.searchVendorLocation(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
                this.vendorLocationModalAddUpdate = false; // Modal'ı kapattık
              }

              Vue.swal({
                icon: response.data.result,
                title: this.vendorLocationModalAddUpdateOptions.title,
                html: response.data.message,
                confirmButtonText: "Tamam",
              });
            });
          }
        }
      );
    },
    vendorLocationPairing() {
      // true ise form validation hatası yok
      this.$Progress.start(); // Progress true ise gösteriyoruz
      this.vendorLocationModalAddUpdateBtnDisable = true; // İşlemi yaptıran button'u disable ettik.

      axios
        .put(
          process.env.VUE_APP_API_URL + "admin/vendor/locations/pairing",
          this.pairingForm,
          { withCredentials: true }
        )
        .then((response) => {
          this.$Progress.finish(); // Progress true ise gizliyoruz
          this.vendorLocationModalAddUpdateBtnDisable = false; // İşlemi yaptıran button'u enable ettik.

          if (response.data.result === "success") {
            if (this.vendorLocationsGridShow) this.searchVendorLocation(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
            this.pairingModalStatus = false; // Modal'ı kapattık
          }

          Vue.swal({
            icon: response.data.result,
            title: "Toplu Eşleştirme",
            html: response.data.message,
            confirmButtonText: "Tamam",
          });
        });
    },
    updateLocationGeo() {
      // true ise form validation hatası yok
      this.$Progress.start(); // Progress true ise gösteriyoruz
      this.vendorLocationModalAddUpdateBtnDisable = true; // İşlemi yaptıran button'u disable ettik.
      console.log("asfdsadf", this.pairingForm);
      axios
        .put(
          process.env.VUE_APP_API_URL + "admin/vendor/locations-geo",
          this.pairingForm,
          { withCredentials: true }
        )
        .then((response) => {
          this.$Progress.finish(); // Progress true ise gizliyoruz
          this.vendorLocationModalAddUpdateBtnDisable = false; // İşlemi yaptıran button'u enable ettik.

          if (response.data.result === "success") {
            if (this.vendorLocationsGridShow) this.searchVendorLocation(); // AG Grid açıksa (Yani daha önce arama yapıldıysa) son aramayı yeniden yap
            this.pairingLocationModalStatus = false; // Modal'ı kapattık
          }
          Vue.swal({
            icon: response.data.result,
            title: "Toplu Eşleştirme",
            html: response.data.message,
            confirmButtonText: "Tamam",
          });
        });
    },

    deleted(id) {
      Vue.swal
        .fire({
          title: "Uyarı ?",
          text: "Bu lokasyonu silmek istediğinize emin misiniz?",
          icon: "question",
          showCancelButton: true,
          showCloseButton: true,
          confirmButtonText: "Evet",
          cancelButtonText: "Hayır",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$Progress.start();
            try {
              axios.delete(
                process.env.VUE_APP_API_URL + "admin/vendor/locations/" + id,
                { withCredentials: true }
              );
              this.$Progress.finish();

              this.$store.dispatch({
                type: "successSwal",
                text: "Lokasyon başarılı bir şekilde silindi",
                refresh: true,
              });
            } catch (error) {
              Vue.swal.fire({
                icon: "error",
                showCloseButton: true,
                text: error.response.data.message,
              });
            }
          }
        });
    },
    onRowDoubleClicked(params) {
      var _this = this;
      _this.cleanVendorLocationModalAddUpdateForm();
      _this.vendorLocationModalAddUpdateOptions.process = "put";
      _this.vendorLocationModalAddUpdateOptions.title =
        "Firma Lokasyonu Güncelle";

      _this.vendorLocationModalAddUpdateForm._id = params.node.data._id;
      _this.vendorLocationModalAddUpdateForm.locationGeo.lat =
        params.node.data.locationGeo?.coordinates[1] || 0;
      _this.vendorLocationModalAddUpdateForm.locationGeo.lng =
        params.node.data.locationGeo?.coordinates[0] || 0;
      _this.vendorLocationModalAddUpdateForm.status = params.node.data.status;
      _this.vendorLocationModalAddUpdateForm.vendorLocationName =
        params.node.data.vendorLocationName;
      _this.vendorLocationModalAddUpdateForm.vendorLocationCode =
        params.node.data.vendorLocationCode;
      _this.vendorLocationModalAddUpdateForm.city = params.node.data.city;
      _this.vendorLocationModalAddUpdateForm.countryCode =
        params.node.data.countryCode;
      _this.vendorLocationModalAddUpdateForm.address = params.node.data.address;
      _this.vendorLocationModalAddUpdateForm.vendorSlug =
        params.node.data.vendorSlug;
      _this.vendorLocationModalAddUpdateForm.email = params.node.data.email;
      _this.vendorLocationModalAddUpdateForm.telephone =
        params.node.data.telephone;
      _this.vendorLocationModalAddUpdateForm.deliveryType =
        params.node.data.deliveryType;
      _this.vendorLocationModalAddUpdateForm.locationSlug =
        params.node.data.locationSlug;
      _this.vendorLocationModalAddUpdateForm.boardingpass =
        params.node.data.boardingPassRequirement;

      _this.vendorLocationModalAddUpdate = true;
    },
  },
};
</script>

<style>
.autocomplete input {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
}
.pac-container {
  z-index: 999999;
}
</style>
